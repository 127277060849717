<template>
  <Top />
  <div class="panel">
    <PlaylistEdit
      v-if="edit"
      :group="props.group"
      :playlist="props.playlist"
      @change="save"
      @cancel="cancel"
    />
    <template v-else>
      <div class="panel-heading">
        <div class="level is-mobile">
          <div class="level-left">{{ playlistList.name }}</div>
          <div class="level-right">
            <PlaylistDownload :group="props.group" :playlist="props.playlist" />
          </div>
          <div class="level-right">
            <button
              v-if="store.canEdit(props.group)"
              class="button is-small"
              @click="edit = true"
            >
              Edit
            </button>
          </div>
        </div>
      </div>
      <Playlist :group="props.group" :playlist="props.playlist" />
    </template>
  </div>
</template>

<script setup lang="ts">
import { ref, Ref } from "vue";
import { useRouter } from "vue-router";
import { useRehorseStore } from "../store";
import Top from "../components/Top.vue";
import Playlist from "../components/Playlist.vue";
import PlaylistEdit from "../components/PlaylistEdit.vue";
import PlaylistDownload from "../components/PlaylistDownload.vue";
import type { Playlist as PlaylistType } from "../../shared/rehorse";
import type { PlaylistId } from "../../shared/ids";

const props = defineProps<{
  group: string;
  playlist: PlaylistId;
}>();

const store = useRehorseStore();
const router = useRouter();
const hasPlaylist = store.hasPlaylist(props.group, props.playlist);
const playlistList: Ref<PlaylistType> = ref({ name: "", items: [] });
if (hasPlaylist) {
  playlistList.value = store.getPlaylist(props.group, props.playlist);
}

const edit = ref(!hasPlaylist);

async function save(newPlaylist: PlaylistType) {
  playlistList.value = newPlaylist;
  store.setPlaylist(props.group, props.playlist, newPlaylist);
  await store.save(props.group);
  edit.value = false;
}
function cancel() {
  if (hasPlaylist) {
    edit.value = false;
  } else {
    router.go(-1);
  }
}
</script>
