import { Point } from "../../shared/annotations";

export const round = (v: number): number => {
  return Math.round(v * 10) / 10;
};
export function transform(translate: Point | undefined): string {
  if (translate) {
    return `translate(${round(translate.x)} ${round(translate.y)})`;
  }
  return "";
}

export const inverseColor = (color: string) => {
  color = color.substring(1);
  const parsed = parseInt(color, 16);
  const inverted = 0xffffff ^ parsed;
  color = inverted.toString(16);
  return `#${(`000000` + color).slice(-6)}`;
};
