<template>
  <button
    class="button"
    :class="{
      'is-inverted': isActive,
    }"
    :style="'background-color: ' + bgcolor"
    @click="click"
  >
    <span :title class="icon" :style="'color:' + fgcolor">{{ text }}</span>
  </button>
</template>
<script setup lang="ts">
import { computed } from "vue";
import { PdfViewMode, editMode, insertText } from "./PdfViewOptions";
import { inverseColor } from "./draw-utils";

const props = defineProps<{
  color: string;
  mode: PdfViewMode;
  title: string;
  text: string;
}>();
const emit = defineEmits<(e: "change", mode: PdfViewMode) => void>();
const invertedColor = inverseColor(props.color);
const isActive = computed(() => {
  return props.mode.isInsertText && props.mode.insertText === props.text;
});
const fgcolor = computed(() => (isActive.value ? props.color : invertedColor));
const bgcolor = computed(() => (isActive.value ? invertedColor : props.color));
function click() {
  if (props.mode.isInsertText && props.mode.insertText === props.text) {
    emit("change", editMode());
  } else {
    emit("change", insertText(props.text));
  }
}
</script>
