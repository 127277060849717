// SPDX-FileCopyrightText: 2023 Jos van den Oever <rehorse@vandenoever.info>
//
// SPDX-License-Identifier: AGPL-3.0-only

import { computed, ComputedRef, ref, Ref } from "vue";
import { defineStore } from "pinia";
import type { User } from "../shared/cookie";
import { io as cookie_io } from "../shared/cookie";
import type { Group } from "../shared/group";
import type { Mark, Piece, Playlist, Arrangement } from "../shared/rehorse";
import { PartAnnotations, annotationsIO } from "../shared/annotations";
import { io } from "../shared/rehorse";
import type { ArrangementId, PieceId, PlaylistId } from "../shared/ids";
import { useCookies } from "@vueuse/integrations/useCookies";
import jwt_decode from "jwt-decode";
import { Persons } from "./stores/people";
import { Arrangements } from "./stores/arrangements";
import { groupListLoader } from "./loaders/groups";
import {
  AgendaData,
  RehorseData,
  rehorseLoaderOptions,
} from "./loaders/rehorse";
import { online } from "./online";
import { cacheFile } from "./cachedb";

export interface SetPiece {
  index: PieceId;
  title: string;
  marks: Mark[];
}

function rehorseUrl(group: string) {
  return `groups/${group}/rehorse.json`;
}

/// Write the state to the server
async function writeData(group: string, state: RehorseData) {
  const json: string = io.encode({
    agenda: state.agenda,
    pieces: state.pieces,
    playlists: state.playlists,
    persons: state.persons.people,
    arrangements: state.arrangements.arrangements,
  });
  const url = rehorseUrl(group);
  try {
    // also keep this version locally
    await cacheFile(url, new Blob([json], { type: "application/json" }));
  } catch (_e) {
    //ignore
  }
  await fetch(rehorseUrl(group), {
    headers: {
      "Content-Type": "application/json",
    },
    method: "PUT",
    body: json,
  });
}

const cookies = useCookies(["auth"]);

function getUserFromCookie(): User | undefined {
  const auth_cookie: unknown = cookies.get("auth");
  if (auth_cookie !== undefined) {
    // Check the expiration of the cookie.
    // To make sending PUT or POST with an expired cookie, require that
    // the session is valid for at least another day.
    // It is hard to come up with a nice ui that refreshes the cookie before
    // sending a PUT or POST. Requiring the cookie to be valid for another
    // day might help to prevent failing PUT or POST
    const result = cookie_io.decode(auth_cookie);
    if (result.isErr || result.value.token === undefined) {
      cookies.remove("auth");
      return;
    }
    // when working offline, ignore the expiration time of the cookie
    if (!online.value) {
      return result.value;
    }
    const decoded = jwt_decode(result.value.token);
    if (decoded !== null && typeof decoded === "object" && "exp" in decoded) {
      const exp = decoded.exp;
      const day = 24 * 3600 * 1000;
      if (typeof exp === "number" && exp * 1000 > Date.now() + day) {
        // token will expire after more than a day and is safe to use
        return result.value;
      }
      cookies.remove("auth");
    }
  }
  // require a new login
  return;
}

export const useRehorseStore = defineStore("rehorse", () => {
  const groupsHandle = groupListLoader.get(ref("api/groups"));
  const groups = computed(() => groupsHandle.value.status.value);
  const user = ref(getUserFromCookie());
  const groupsData = ref(new Map<string, RehorseData>());
  const annotations = new Map<string, Ref<PartAnnotations>>();
  function checkUser() {
    user.value = getUserFromCookie();
  }
  function logout() {
    cookies.remove("auth");
    user.value = undefined;
    // groups.value = [];
  }
  function canEdit(group: string): boolean {
    if (!groups.value.isReady || !online.value) {
      return false;
    }
    const g = groups.value.data.find((v) => v.groupname === group);
    if (g && user.value) {
      return g.admins.includes(user.value.username);
    }
    return false;
  }
  function getPlaylists(group: string): Map<PlaylistId, Playlist> {
    return getData(group).playlists;
  }
  function getPieces(group: string): ComputedRef<Map<PieceId, Piece>> {
    return computed(() => getData(group).pieces);
  }
  function getPersons(group: string): ComputedRef<Persons> {
    return computed(() => getData(group).persons);
  }
  function getAgenda(group: string): AgendaData {
    return getData(group).agenda;
  }
  function getArrangements(group: string): ComputedRef<Arrangements> {
    return computed(() => getData(group).arrangements);
  }
  function getArrangement(
    group: string,
    arrangement: ArrangementId,
  ): Arrangement {
    const data = getData(group);
    const a = data.arrangements.arrangements.get(arrangement);
    if (!a) {
      throw Error(`Arrangement '${arrangement}' was not found.`);
    }
    return a;
  }
  function hasPlaylist(group: string, playlist: PlaylistId): boolean {
    const data = getData(group);
    const pl = data.playlists.get(playlist);
    return pl !== undefined;
  }
  function getPlaylist(group: string, playlist: PlaylistId): Playlist {
    const data = getData(group);
    const pl = data.playlists.get(playlist);
    if (!pl) {
      throw Error(`Playlist '${playlist}' was not found.`);
    }
    return pl;
  }
  function setPlaylist(group: string, id: PlaylistId, playlist: Playlist) {
    const data = getData(group);
    data.playlists.set(id, playlist);
  }
  function getPiece(group: string, uuid: PieceId): Piece {
    const data = getData(group);
    const piece = data.pieces.get(uuid);
    if (!piece) {
      throw Error(`Piece '${uuid}' was not found.`);
    }
    return piece;
  }
  async function addPiece(group: string, piece_id: PieceId, piece: Piece) {
    const data = getData(group);
    data.pieces.set(piece_id, piece);
    await writeData(group, data);
  }
  async function loadData(group: string) {
    await groupsHandle.value.wait();
    // check that the group exists and throw an error if it does not
    getGroup(group);
    // if the user is not logged in, it is ok for data to be stale
    if (groupsData.value.get(group) && !user.value) {
      return;
    }
    // if the user is logged in, refresh the data to avoid editing stale
    // data
    const data = await rehorseLoaderOptions.getter(
      rehorseUrl(group),
      (freshData) => {
        groupsData.value.set(group, freshData);
      },
    );
    groupsData.value.set(group, data);
  }
  function getData(group: string): RehorseData {
    const d = groupsData.value.get(group);
    if (!d) {
      throw Error(`Data for group '${group}' was not found.`);
    }
    return d;
  }
  async function loadAnnotations(url: string) {
    const a = getAnnotations(url);
    if (a.value.pageAnnotations.length !== 0) {
      return;
    }
    try {
      const response = await fetch(url);
      const result = annotationsIO.decode(await response.json());
      if (result.isErr) {
        console.error(result.value);
      } else {
        a.value = result.value;
      }
    } catch (error) {
      console.error(error);
    }
  }
  function getAnnotations(url: string): Ref<PartAnnotations> {
    let a = annotations.get(url);
    if (!a) {
      a = ref({ pageAnnotations: [] });
      annotations.set(url, a);
    }
    return a;
  }
  function getGroup(group: string): Group {
    if (!groups.value.isReady) {
      throw Error(`Group '${group}' was not found.`);
    }
    const g = groups.value.data.find((v) => v.groupname === group);
    if (!g) {
      throw Error(`Group '${group}' was not found.`);
    }
    return g;
  }
  async function changePiece(group: string, setPiece: SetPiece) {
    const data = getData(group);
    const piece = data.pieces.get(setPiece.index);
    if (piece) {
      piece.title = setPiece.title;
      piece.marks = setPiece.marks;
      await writeData(group, data);
    }
  }
  async function save(group: string) {
    const data = getData(group);
    await writeData(group, data);
  }
  async function saveAnnotations(url: string, newAnnotations: PartAnnotations) {
    const a = annotations.get(url);
    if (a) {
      a.value = newAnnotations;
    }
    await fetch(url, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "PUT",
      body: annotationsIO.encode(newAnnotations),
    });
  }
  return {
    addPiece,
    annotations,
    canEdit,
    changePiece,
    checkUser,
    getAgenda,
    getAnnotations,
    getArrangement,
    getArrangements,
    getGroup,
    getPersons,
    getPiece,
    getPieces,
    getPlaylist,
    getPlaylists,
    groups,
    hasPlaylist,
    loadAnnotations,
    loadData,
    logout,
    save,
    saveAnnotations,
    setPlaylist,
    user,
  };
});
