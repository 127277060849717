<template>
  <div class="panel-heading">
    <div class="level is-mobile">
      <div class="level-left">Edit event</div>
      <div class="level-right">
        <button
          class="button is-small"
          :disabled="!isAcceptable()"
          @click="save"
        >
          Save
        </button>
        <button class="button is-small" @click="emit('cancel')">Cancel</button>
      </div>
    </div>
  </div>
  <div class="column">
    <div class="name-value-grid">
      <label for="date">Date</label>
      <input
        id="date"
        v-model="date"
        class="level-item"
        type="date"
        @change="change"
      />
      <label for="startTime">Start</label>
      <input
        id="startTime"
        v-model="startTime"
        name="startTime"
        type="time"
        @change="change"
      />
      <label for="endTime">End</label>
      <input
        id="endTime"
        v-model="endTime"
        name="endTime"
        type="time"
        @change="change"
      />
      <label for="location">Location</label>
      <Multiselect
        id="location"
        v-model="location"
        name="location"
        placeholder="Location"
        :taggable="true"
        :options="locations"
        :custom-label="locationLabel"
        @tag="addLocation"
      >
      </Multiselect>
      <label for="cancelled">Cancelled</label>
      <input
        id="cancelled"
        v-model="cancelled"
        name="cancelled"
        type="checkbox"
      />
      <label for="remarks">Remarks</label>
      <textarea id="remarks" v-model="remarks" name="remarks"></textarea>
    </div>
    <div class="panel-heading">Playlist</div>
    <PlaylistEditInner
      :group="props.group"
      :items="playlistItems"
      @change="playlistChange"
    />
  </div>
</template>
<script setup lang="ts">
import { computed, ref, Ref } from "vue";
import Multiselect from "vue-multiselect";
import PlaylistEditInner from "./PlaylistEditInner.vue";
import { useRehorseStore } from "../store";
import {
  isDateTime,
  localeTime,
  DateTime,
  MusicEvent,
} from "../../shared/agenda";
import { PlaylistItem } from "../../shared/rehorse";
import { createId, LocationId, PlaylistId } from "../../shared/ids";

const props = defineProps<{
  group: string;
  event: MusicEvent | null;
}>();

const emit = defineEmits<{
  (e: "change", event: MusicEvent): void;
  (e: "cancel"): void;
}>();

const store = useRehorseStore();
const date = ref("");
const startTime = ref("");
const endTime = ref("");
const remarks = ref("");
const cancelled = ref(false);
if (props.event) {
  date.value = props.event.start.slice(0, 10);
  startTime.value = localeTime(props.event.start);
  endTime.value = localeTime(props.event.end);
  remarks.value = props.event.remarks || "";
  cancelled.value = props.event.cancelled || false;
}
const agenda = store.getAgenda(props.group);
const locations = computed(() => [...agenda.locations.keys()]);
const location: Ref<LocationId | undefined> = ref(props.event?.location);
function getPlaylist(): PlaylistId {
  const id = props.event?.playlist;
  if (id) {
    return id;
  }
  const newId = createId<PlaylistId>();
  store.setPlaylist(props.group, newId, { name: "", items: [] });
  return newId;
}
const initialPlaylist = getPlaylist();
const playlist = ref(initialPlaylist);
const playlistItems = ref(
  store.getPlaylist(props.group, initialPlaylist).items,
);

function dateTime(time: string): DateTime | undefined {
  const d = Date.parse(`${date.value}T${time}`);
  if (!isNaN(d)) {
    const iso = new Date(d).toISOString();
    if (isDateTime(iso)) {
      return iso;
    }
  }
  return;
}
function makeMusicEvent(): MusicEvent | undefined {
  const start = dateTime(startTime.value);
  const end = dateTime(endTime.value);
  if (start && end && location.value) {
    return {
      start,
      end,
      location: location.value,
      remarks: remarks.value,
      playlist: playlist.value,
      cancelled: cancelled.value,
    };
  }
  return;
}
function isAcceptable(): boolean {
  return makeMusicEvent() !== undefined;
}
function toTimeString(input: Date): string {
  const hours = `00${input.getHours()}`.slice(-2);
  const minutes = `00${input.getMinutes()}`.slice(-2);
  return `${hours}:${minutes}`;
}
function change() {
  // make a guess for the end time based on the start time
  if (startTime.value && !endTime.value) {
    const start = dateTime(startTime.value);
    if (start) {
      const end = new Date(Date.parse(start) + 2 * 3600000);
      endTime.value = toTimeString(end);
    }
  }
}
function playlistChange(newItems: PlaylistItem[]) {
  playlistItems.value = newItems;
}
function save() {
  const event = makeMusicEvent();
  if (!event) {
    return;
  }
  store.setPlaylist(props.group, playlist.value, {
    name: "",
    items: playlistItems.value,
  });
  emit("change", event);
}
async function addLocation(newLocation: string) {
  const id = createId<LocationId>();
  agenda.locations.set(id, { name: newLocation });
  await store.save(props.group);
  location.value = id;
}
function locationLabel(id: LocationId) {
  const l = agenda.locations.get(id);
  if (l) {
    return l.name;
  }
  return "";
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
