<template>
  <div class="panel-heading">
    <div class="level is-mobile">
      <div class="level-left">Edit arrangement</div>
      <div class="level-right">
        <button
          class="button is-small"
          :disabled="!titleIsAcceptable()"
          @click="save"
        >
          Save
        </button>
        <button class="button is-small" @click="emit('cancel')">Cancel</button>
      </div>
    </div>
  </div>
  <div class="control level-left">
    <input v-model="title" class="input" type="text" placeholder="Title" />
  </div>
  <div>
    <Multiselect
      v-model="composers"
      placeholder="Composers"
      :multiple="true"
      :taggable="true"
      :options="people"
      @tag="addComposer"
    ></Multiselect>
  </div>
  <div>
    <Multiselect
      v-model="arrangers"
      placeholder="Arrangers"
      :multiple="true"
      :taggable="true"
      :options="people"
      @tag="addArranger"
    ></Multiselect>
  </div>
  <div>
    <Multiselect
      v-model="lyricists"
      placeholder="Lyricists"
      :multiple="true"
      :taggable="true"
      :options="people"
      @tag="addLyricist"
    ></Multiselect>
  </div>
  <div class="control level-left">
    <input v-model="version" class="input" type="text" placeholder="Version" />
  </div>
  <div>
    <Multiselect
      v-model="recordings"
      placeholder="Recordings"
      :multiple="true"
      :options="pieces"
      track-by="id"
      label="label"
    ></Multiselect>
  </div>
  <div class="panel-block">
    <Upload
      accept="application/pdf"
      :post-url="`groups/${props.group}/arrangement/${props.id}/pdf`"
      @uploaded="uploaded"
    />
  </div>
  <div v-if="!allPartsDefined" class="notification is-warning">
    Not all parts have been assigned. Please upload pdfs and assign them to the
    parts.
  </div>
  <div class="columns">
    <div class="column">
      <VuePdfEmbed
        v-if="currentPdfUrl"
        v-bind="pdfArgs"
        :source="currentPdfUrl"
        :page="currentPdfPage"
        :rotation="currentPdfRotation"
        :disable-annotation-layer="true"
        :disable-text-layer="true"
      />
    </div>
    <div class="column parts-selector">
      <div v-for="[partId, part] of group.parts" :key="partId">
        <PartSelector
          :part="part"
          :part-id="partId"
          :arrangement-part="getPart(partId)"
          :pdfs="pdfs"
          @change-page="changePage"
          @change="changePart"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  Ref,
  computed,
  defineAsyncComponent,
  onMounted,
  onUnmounted,
  ref,
} from "vue";
import Multiselect from "vue-multiselect";
import { useRehorseStore } from "../store";
import { newArrangement } from "../stores/arrangements";
import type { Arrangement, ArrangementPart, Pdf } from "../../shared/rehorse";
import { io_upload_pdf } from "../../shared/rehorse";
import type { PartId, PersonId, PieceId } from "../../shared/ids";
import { Persons } from "../stores/people";
import Upload from "./Upload.vue";
import PartSelector from "./PartSelector.vue";
import { PdfArgs } from "./PdfViewOptions";

interface Props {
  group: string;
  id?: string;
  arrangement?: Arrangement;
  persons: Persons;
}

const props = withDefaults(defineProps<Props>(), {
  id: "",
  arrangement: newArrangement,
});

const emit = defineEmits<{
  (e: "change", arrangement: Arrangement): void;
  (e: "cancel"): void;
}>();

const VuePdfEmbed = defineAsyncComponent(() => import("vue-pdf-embed"));

interface RecordingOption {
  id: PieceId;
  label: string;
}

const people = computed(() => {
  return props.persons.listNames();
});
const store = useRehorseStore();
const group = store.getGroup(props.group);
const pieces = computed((): RecordingOption[] => {
  const ps = store.getPieces(props.group);
  const options: RecordingOption[] = [];
  for (const [id, piece] of ps.value.entries()) {
    options.push({
      id,
      label: piece.title,
    });
  }
  return options;
});

const title = ref(props.arrangement.title);
const version = ref(props.arrangement.version);
const composers = ref(mapToNames(props.arrangement.composers));
const arrangers = ref(mapToNames(props.arrangement.arrangers));
const lyricists = ref(mapToNames(props.arrangement.lyricists));
const recordings = ref(mapToRecordings(props.arrangement.recordings));
const pdfs = ref([...props.arrangement.pdfs]);
const parts = ref(props.arrangement.parts);
const allPartsDefined = computed(() => {
  return [...group.parts.keys()].every((v) => {
    return parts.value.get(v);
  });
});
const currentPdfUrl = ref();
const currentPdfPage = ref(1);
const currentPdfRotation = ref(0);
const pdfArgs: Ref<PdfArgs> = ref({
  width: window.innerWidth / 2,
});

function onResize() {
  const w = window.innerWidth / 2;
  if (currentPdfRotation.value === 0 || currentPdfRotation.value === 180) {
    pdfArgs.value = { width: w };
  } else {
    pdfArgs.value = { height: w };
  }
}

onMounted(() => {
  window.addEventListener("resize", onResize);
});
onUnmounted(() => {
  window.removeEventListener("resize", onResize);
});

function titleIsAcceptable() {
  return title.value !== "";
}

function addComposer(newName: string) {
  addPerson(newName);
  composers.value.push(newName);
}
function addArranger(newName: string) {
  addPerson(newName);
  arrangers.value.push(newName);
}
function addLyricist(newName: string) {
  addPerson(newName);
  lyricists.value.push(newName);
}

function mapToNames(ids: PersonId[]): string[] {
  return [
    ...ids.map((id) => {
      return props.persons.people.get(id)?.name ?? "";
    }),
  ];
}

function mapToRecordings(ids: PieceId[]): RecordingOption[] {
  return [
    ...ids.map((id) => {
      return {
        id,
        label: store.getPiece(props.group, id).title,
      };
    }),
  ];
}

function addPerson(name: string): PersonId {
  let id = props.persons.findPerson(name);
  if (!id) {
    id = props.persons.addPerson({ name });
  }
  return id;
}

function mapToPersons(names: string[]) {
  const persons = [];
  for (const name of names) {
    persons.push(addPerson(name));
  }
  return persons;
}

function save() {
  if (!titleIsAcceptable()) {
    return;
  }
  const arrangement: Arrangement = {
    title: title.value,
    composers: mapToPersons(composers.value),
    arrangers: mapToPersons(arrangers.value),
    lyricists: mapToPersons(lyricists.value),
    recordings: recordings.value.map((r) => r.id),
    pdfs: pdfs.value,
    parts: parts.value,
  };
  if (version.value) {
    arrangement.version = version.value;
  }
  emit("change", arrangement);
  title.value = "";
  version.value = "";
  composers.value = [];
  arrangers.value = [];
  lyricists.value = [];
  recordings.value = [];
  pdfs.value = [];
  parts.value = new Map();
}
function uploaded(_index: number, _total: number, response: unknown) {
  const result = io_upload_pdf.decode(response);
  if (result.isErr) {
    throw result.value;
  }
  pdfs.value.push(result.value);
}
function changePage(pdf: Pdf | undefined, page: number, rotation: number) {
  if (pdf) {
    currentPdfUrl.value = `groups/${props.group}/arrangement/${props.id}/pdf/${pdf.sha256}`;
    currentPdfPage.value = page;
    currentPdfRotation.value = rotation;
  } else {
    currentPdfUrl.value = "";
  }
  onResize();
}
function getPart(partId: PartId): ArrangementPart | null | undefined {
  const pts = props.arrangement.parts.get(partId);
  if (pts === undefined) {
    return undefined;
  }
  if (pts.length === 0) {
    return null;
  }
  return pts[0];
}
function changePart(partId: PartId, part: ArrangementPart | undefined | null) {
  if (part === undefined) {
    parts.value.delete(partId);
  } else if (part === null) {
    parts.value.set(partId, []);
  } else {
    parts.value.set(partId, [part]);
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style>
div.parts-selector {
  height: 100vh;
  overflow-y: scroll;
}
</style>
