// SPDX-FileCopyrightText: 2024 Jos van den Oever <rehorse@vandenoever.info>
//
// SPDX-License-Identifier: AGPL-3.0-only

import { useRehorseStore } from "./store";
import { Part, Group } from "../shared/group";
import { Arrangement, ArrangementPart } from "../shared/rehorse";
import { ArrangementId, PartId, PlaylistId } from "../shared/ids";
import { pdfLoaderOptions } from "./loaders/pdf";
import { currentDateTime } from "../shared/agenda";
import { listCacheFiles } from "./cachedb";
import { online } from "./online";
import { audioLoaderOptions, getAudioUri } from "./loaders/audio";
import { ref } from "vue";

export interface APart {
  partId: PartId;
  part: Part;
  a?: ArrangementPart[] | null;
}

export const getUserParts = (
  user: string | undefined,
  arrangement: Arrangement | undefined,
  group: Group,
): APart[] => {
  const userparts = [];
  for (const [partId, part] of group.parts.entries()) {
    if (part.members.some((m) => m === user)) {
      const a = arrangement?.parts.get(partId) ?? null;
      userparts.push({ partId, part, a });
    }
  }
  for (const [partId, part] of group.parts.entries()) {
    if (part.backups.some((m) => m === user)) {
      const a = arrangement?.parts.get(partId) ?? null;
      userparts.push({ partId, part, a });
    }
  }
  return userparts;
};

export const makePartUrl = (
  group: string,
  arrangement: ArrangementId,
  part: string,
): string => {
  const url = `groups/${group}/arrangement/${arrangement}/part/${part}`;
  return url;
};

interface DesiredArrangement {
  group: Group;
  id: ArrangementId;
  arrangement: Arrangement;
}

const getDesiredArrangements = async (): Promise<DesiredArrangement[]> => {
  const arrs: DesiredArrangement[] = [];
  const store = useRehorseStore();
  const user = store.user?.username;
  if (user === undefined || !store.groups.isReady) {
    return arrs;
  }
  const now = currentDateTime();
  for (const group of store.groups.data) {
    await store.loadData(group.groupname);
    const agenda = store.getAgenda(group.groupname);
    const playlists = new Set<PlaylistId>();
    for (const [_, concert] of agenda.concerts) {
      if (concert.end < now) {
        continue;
      }
      playlists.add(concert.playlist);
    }
    for (const [_, rehearsal] of agenda.rehearsals) {
      if (rehearsal.end < now) {
        continue;
      }
      playlists.add(rehearsal.playlist);
    }
    const arrangements = new Set<ArrangementId>();
    for (const playlistId of playlists) {
      const playlist = store.getPlaylist(group.groupname, playlistId);
      for (const arrangement of playlist.items) {
        if (arrangement.arrangement) {
          arrangements.add(arrangement.arrangement);
        }
      }
    }
    for (const arrangementId of arrangements) {
      const arrangement = store.getArrangement(group.groupname, arrangementId);
      arrs.push({
        group,
        id: arrangementId,
        arrangement,
      });
    }
  }
  return arrs;
};

const getDesiredPartUrls = (arrs: DesiredArrangement[]): Set<string> => {
  const store = useRehorseStore();
  const user = store.user?.username;
  const urls = new Set<string>();
  for (const arr of arrs) {
    const parts = getUserParts(user, arr.arrangement, arr.group);
    for (const part of parts) {
      if (part.a) {
        const url = makePartUrl(arr.group.groupname, arr.id, part.partId);
        urls.add(url);
      }
    }
  }
  return urls;
};

const getDesiredAudioUrls = (arrs: DesiredArrangement[]): Set<string> => {
  const store = useRehorseStore();
  const urls = new Set<string>();
  for (const arr of arrs) {
    for (const r of arr.arrangement.recordings) {
      const piece = store.getPiece(arr.group.groupname, r);
      urls.add(getAudioUri(arr.group.groupname, r, ref(piece)));
    }
  }
  return urls;
};

export const getDesiredUrls = async () => {
  const arrs = await getDesiredArrangements();
  const urls = new Map<string, (uri: string) => Promise<object>>();
  getDesiredPartUrls(arrs).forEach((url) => {
    urls.set(url, pdfLoaderOptions.getter);
  });
  getDesiredAudioUrls(arrs).forEach((url) => {
    urls.set(url, audioLoaderOptions.getter);
  });
  return urls;
};

export const retrieveUrl = async (url: string) => {
  await pdfLoaderOptions.getter(url);
};

interface QueueEntry {
  url: string;
  getter: (uri: string) => Promise<object>;
}

const queue: QueueEntry[] = [];
let queueIsRunning = false;

const runQueue = async () => {
  if (queueIsRunning) {
    return;
  }
  queueIsRunning = true;
  let entry = queue.pop();
  while (entry) {
    try {
      await entry.getter(entry.url);
    } catch (_e) {
      //ignore
    }
    entry = queue.pop();
  }
  queueIsRunning = false;
};

export const fillCache = async () => {
  if (!online.value) {
    return;
  }
  const urls = await getDesiredUrls();
  const cachedFiles = await listCacheFiles("url");
  for (const cachedFile of cachedFiles) {
    urls.delete(cachedFile.url);
  }
  for (const queuedUrl of queue) {
    urls.delete(queuedUrl.url);
  }
  for (const [url, getter] of urls) {
    queue.push({ url, getter });
  }
  void runQueue();
};
