<template>
  <div
    v-if="parts.size && online"
    class="dropdown is-right"
    :class="{ 'is-active': isActive }"
  >
    <div class="dropdown-trigger">
      <button
        class="button is-small"
        aria-haspopup="true"
        aria-controls="dropdown-menu"
        @click="toggle"
      >
        <span>Download</span>
        <span class="icon is-small">
          <i class="fas fa-angle-down" aria-hidden="true"></i>
        </span>
      </button>
    </div>
    <div id="dropdown-menu" class="dropdown-menu" role="menu">
      <div class="dropdown-content">
        <a
          v-for="part of parts"
          :key="part[0]"
          class="dropdown-item"
          @click="zip(part)"
        >
          {{ part[1].part.name }}<br />
          <span class="is-size-7">{{ part[1].parts.length }} files</span>
        </a>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, ref } from "vue";
import { ZipArchive } from "@shortercode/webzip";
import { online } from "../online";
import { getUserParts, makePartUrl } from "../cache";
import { useRehorseStore } from "../store";
import { PartId, PlaylistId } from "../../shared/ids";
import { Arrangement, ArrangementPart } from "../../shared/rehorse";
import { Part } from "../../shared/group";
import { saveBlob } from "../download";
import { cachedLoad } from "../loaders/cached-loader";

const props = defineProps<{
  group: string;
  playlist: PlaylistId;
}>();

interface ArrangementAndPart {
  arrangement: Arrangement;
  part: ArrangementPart[];
}

interface Parts {
  part: Part;
  parts: ArrangementAndPart[];
}

const store = useRehorseStore();
const playlist = store.getPlaylist(props.group, props.playlist);
// const name = playlist.name;
const parts = computed((): Map<PartId, Parts> => {
  const user = store.user?.username;
  const group = store.getGroup(props.group);
  const p = new Map<PartId, Parts>();
  for (const item of playlist.items) {
    if (item.arrangement) {
      const arr = store.getArrangement(props.group, item.arrangement);
      const userParts = getUserParts(user, arr, group);
      for (const part of userParts) {
        if (part.a) {
          const list: Parts = p.get(part.partId) ?? {
            part: part.part,
            parts: [],
          };
          list.parts.push({ arrangement: arr, part: part.a });
          p.set(part.partId, list);
        }
      }
    }
  }
  return p;
});

const zip = async function (part: [PartId, Parts]) {
  const archive = new ZipArchive();
  for (const item of playlist.items) {
    if (item.arrangement) {
      const arr = store.getArrangement(props.group, item.arrangement);
      if (!arr.parts.has(part[0])) {
        continue;
      }
      const uri = makePartUrl(props.group, item.arrangement, part[0]);
      const partBlob = await cachedLoad(
        { uri },
        (blob: Blob): Promise<Blob> => {
          return Promise.resolve(blob);
        },
      );
      await archive.set(`${arr.title} - ${part[1].part.name}.pdf`, partBlob);
    }
  }
  saveBlob(
    archive.to_blob(),
    `${props.group} - ${part[1].part.name}.zip`,
    "application/zip",
  );
};
const isActive = ref(false);
function toggle() {
  isActive.value = !isActive.value;
}
</script>
